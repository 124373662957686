import { types } from 'mobx-state-tree';
import { noFeedbackUrl,noFeedbackAcceptanceUrl,noFeedbackReinstateUrl, functionListUrl, reassignFeedbackActorsUrl, feedbackInteractionsUrl, rejectSubmittedFeedbackUrl, submittedFeedbacksUrl } from './APIEndpoints';
import { snackBarStore } from "./SnackBarStore";
import {LOADING,ERROR,DONE,INIT, REJECT_DIALOG_FLAG} from './LabelStore';
import { appStore } from './AppStore';
import { feedbackStore } from './FeedbackStore';
import { feedbackListStore } from './FeedbackListStore';

const Function = types.model({
    id: types.integer,
    name: types.string
});

const Interaction = types.model({
    id: types.integer,
    notes: types.string,
    createdAt: types.Date,
    createdBy: types.string,
    functionName: types.string
})

const FeedbackInteractionStore = types.model({
    feedbackId: types.optional(types.integer, 0),
    feedbackActorId: types.optional(types.integer, 0),
    reason: types.optional(types.string, ''),
    functions: types.optional(types.array(Function), []),
    selectedFunctionIds: types.optional(types.array(types.integer), []),
    interactions: types.optional(types.array(Interaction), []),
    showDiscardDialog: types.optional(types.boolean, false),
    showReassignDialog: types.optional(types.boolean, false),
    showRejectDialog: types.optional(types.boolean,false),
    openDialog: types.optional(types.string,''),
    pageState: types.optional(types.string,INIT)
}).views(self => {
    return {
        validateReassign() {
            if (self.reason === '') {
                snackBarStore.showErrorMessage('Reason should not be empty.');
                return false;
            }

            if (self.selectedFunctionIds.length === 0) {
                snackBarStore.showErrorMessage('Atleast one function should be selected.');
                return false;
            }
            return true;
        },

        selectedFunctions(){
            if(self.feedbackId === 0)
            {
                return [];
            }
            const tempFunctions = []
            self.selectedFunctionIds.map(id=>{
                    tempFunctions.push(self.findFunction(id));
            });
            return tempFunctions;
        },

        getInteractionPost() {
            return { feedbackActorId: self.feedbackActorId, feedbackId: self.feedbackId, reason: self.reason, functionIds: self.selectedFunctionIds  }
        },

        assignmentPost() {
            return {
                feedbackActorId: self.feedbackActorId,
                feedbackId: self.feedbackId, reason: self.reason,
                functionIds: self.selectedFunctionIds
            }
        },

        findFunction(id) {
            return self.functions.find(item => item.id === id);
        },

        isEditable(){
            return (self.pageState === DONE || self.pageState === INIT) && self.feedbackId > 0;
        }
    }
}).actions(self => {
    return {

        updateReason(value) {
            self.reason = value;
        },

        updateOpenDialog(dialogName){
            self.openDialog = dialogName;
        },

        onFunctionSelect(values) {
            self.selectedFunctionIds = [];
            values.map(id => {
                self.selectedFunctionIds.push(id);
            });
        },

        async loadFunctions(apiProxy) {
            try {
                if (self.functions.length !== 0) {
                    return;
                }
                const response = await apiProxy.getAsync(functionListUrl);
                const data = await response.json();
                data.map(item => {
                    self.addFunction(item);
                });
            } catch (e) {
                console.log(e);
            }
        },

        addFunction(item) {
            self.functions.push(Function.create({ id: item.id, name: item.name }));
        },

        async onReassign() {
            const reason = self.reason;
            if (reason == "" || reason == undefined){
                snackBarStore.showErrorMessage('Reason should not be empty.');
                return;
            }
            if (!self.validateReassign) {
                return;
            }
            const feedbackActionPost = self.assignmentPost();
            const response = await appStore.apiProxy.asyncPost(reassignFeedbackActorsUrl, feedbackActionPost);
            const data = await response.json();
            self.handleReassignDialog(false);
            if (data.responseCode === 200) {
                feedbackStore.refresh();
                snackBarStore.showSuccessMessage(data.message);
                window.location.reload();
                return;
            }
            snackBarStore.showErrorMessage(data.message);
            feedbackInteractionStore.handleReassignDialog(true);
        },

        addSelectedFunction(functionItem) {
            if (self.selectedFunctionIds.includes(functionItem.id)) {
                return;
            }
            self.selectedFunctionIds.push(functionItem.id);
        },

        async onDiscard() {
            try {
                if (self.reason === '') {
                    snackBarStore.showErrorMessage('Reason should not be empty.');
                    return;
                }

                const actionPost = self.getInteractionPost();
                const response = await appStore.apiProxy.asyncPost(noFeedbackUrl, actionPost);
                const data = await response.json();
                if (data.responseCode === 200) {
                    feedbackStore.refresh();
                    snackBarStore.showSuccessMessage(data.message);
                    self.handleDiscardDialog(false);
                    return;
                }
                snackBarStore.showErrorMessage(data.message);
            }
            catch (e) {
                console.log(e);
            }
        },

        async onConfirmDiscard() {
            try {
                if (self.reason === '') {
                    snackBarStore.showErrorMessage('Reason should not be empty.');
                    return;
                }

                const actionPost = self.getInteractionPost();
                const response = await appStore.apiProxy.asyncPost(noFeedbackAcceptanceUrl, actionPost);
                const data = await response.json();
                if (data.responseCode === 200) {
                    snackBarStore.showSuccessMessage(data.message);
                    self.handleDiscardDialog(false);
                }
            }
            catch (e) {
                console.log(e);
            }
        },

        async onReinstateFeedback() {
            try {
                if (self.reason === '') {
                    snackBarStore.showErrorMessage('Reason should not be empty.');
                    return;
                }

                const actionPost = self.getInteractionPost();
                const response = await appStore.apiProxy.asyncPost(noFeedbackReinstateUrl, actionPost);
                const data = await response.json();
                if (data.responseCode === 200) {
                    snackBarStore.showSuccessMessage(data.message);
                    self.handleDiscardDialog(false);
                    feedbackStore.reset();
                }
            }
            catch (e) {
                console.log(e);
            }
        },

        handleDiscardDialog(showDialog) {
            self.showDiscardDialog = showDialog;
        },

        handleReassignDialog(showDialog) {
            self.showReassignDialog = showDialog;
        },

        updateFlag(key,showDialog){
            self[key] = showDialog
        },
        
        reset(){
            self.interactions = [];
            self.feedbackId = 0;
        },

        async fetchInteractions(feedbackId, feedbackActorId) {
            try {
                self.updatePageState(LOADING);
                self.setFeedbackId(feedbackId, feedbackActorId);
                const url = feedbackInteractionsUrl + '/' + feedbackId;
                const response = await appStore.apiProxy.getAsync(url);
                const data = await response.json();
                self.populateInteractions(data);
                self.updatePageState(DONE);
            } catch (e) {
                self.updatePageState(ERROR);
                console.log(e);
            }
        },

        updatePageState(pageState){
            self.pageState = pageState;
        },

        setFeedbackId(feedbackId, feedbackActorId) {
            self.feedbackId = feedbackId;
            self.feedbackActorId = feedbackActorId;
        },

        populateInteractions(data) {
            self.interactions = [];
            data.map(item => {
                self.interactions.push(Interaction.create({
                    id: item.actionId, notes: item.notes,
                    createdAt: item.createdAt, createdBy: item.createdBy,
                    functionName: item.functionName
                }))
            });
        },

       async onReject(){
            try {
                if (self.reason === '') {
                    snackBarStore.showErrorMessage('Reason should not be empty.');
                    return;
                }

                const actionPost = self.getInteractionPost();
                const response = await appStore.apiProxy.asyncPost(rejectSubmittedFeedbackUrl, actionPost);
                const data = await response.json();
                if (data.responseCode === 200) {
                    snackBarStore.showSuccessMessage(data.message);
                    self.updateFlag(REJECT_DIALOG_FLAG,false);
                    feedbackListStore.fetchFeedbacks(submittedFeedbacksUrl);
                }

            }catch(e){
                console.log(e);
            }
        }
    }
});
export const feedbackInteractionStore = FeedbackInteractionStore.create({});